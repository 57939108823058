import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const ContactPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Contact" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Contact us
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              We'd love to hear from you
            </h2>
            <br />
            <div className="mt-4 leading-loose">
              <h1 className="text-xl leading-tight font-semibold tracking-tight sm:text-2xl">Address</h1>
              <p>
              104 Soi Sinthon Village, Khlong Chan, Bang Kapi, Bangkok 10240 Thailand.
              </p>
              <br />
              <h1 className="text-xl leading-tight font-semibold tracking-tight sm:text-2xl">E-Mail</h1>
              <a
                href="mailto:support@blueboatgame.com"
                className="text-blue-600 hover:text-blue-800"
              >
                support@blueboatgame.com
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.love.childImageSharp.fluid}
              alt="Love"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query {
    love: file(relativePath: { eq: "love.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
